export default {
	init() {
		// JavaScript to be fired on all pages
		//$('.grid').masonry({
		//	itemSelector: '.grid-item',
		//	columnWidth: 200,
		//});
		$('.navbar-toggler').on('click', function(){
			$('.wrap_menu').toggleClass( 'openLeft' );
			$('body').toggleClass( 'openLeft' );
		});
		if( window.innerWidth > 767 ) {
			$('li.dropdown a').on('click', function() {
				var $a = $(this);
				if ( $a.length && $a.attr('href') ) {
					location.href = $a.attr('href');
				}
			});
		} else {
			$('li.dropdown a').click( function ( e ){
				if(e.target != this) return;
				var $a = $(this);
				if ( $a.length && $a.attr('href') ) {
					location.href = $a.attr('href');
				}
			});
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
