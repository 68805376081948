import Minigrid from 'minigrid';
export default {
	init() {
		console.log( 1 );
		var grid;
		grid = new Minigrid({
			container: '.grid.clearfix',
			item: '.col---12',
			gutter: 0,
		});
		grid.mount();
		function init2() {
			console.log( 'init2' );
		}
		
		// mount
		function update() {
			grid.mount();
		}

		document.addEventListener('DOMContentLoaded', init2);
		window.addEventListener('resize', update);
		$('.grid.clearfix').each(function() { // the containers for all your galleries
			/*
			$(this).magnificPopup({
				delegate: 'a', // the selector for gallery item
				type: 'image',
				gallery: {
					enabled: true,
				},
			});
			*/
			$(this).lightGallery({
				selector: '.open-gallery',
			});
		});

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
