import Minigrid from 'minigrid';
export default {
	init() {
		// JavaScript to be fired on all pages
		//$('.grid').masonry({
		//	itemSelector: '.grid-item',
		//	columnWidth: 200,
		//});
		var grid;
		grid = new Minigrid({
			container: '.grid.clearfix',
			item: '.col---12',
			gutter: 0,
		});
		grid.mount();
		function init2() {
			console.log( 'init2' );
		}
		
		// mount
		function update() {
			grid.mount();
		}

		document.addEventListener('DOMContentLoaded', init2);
		window.addEventListener('resize', update);

		$('.col---12[data-gallery] a').click( function( e ) { // the containers for all your galleries
			e.preventDefault();
			var gallery = $(this).closest( '.col---12' ).data('gallery');
			var items = [];
			gallery.forEach( function ( item ) {
				items.push( {
					src: item,
				} );
			} );
			console.log( items );
			$.magnificPopup.open({
				//delegate: 'a', // the selector for gallery item
				type: 'image',
				items: items,
				gallery: {
					enabled: true,
				},
			});
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
