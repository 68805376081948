// import external dependencies
import 'jquery';
import 'magnific-popup';
import 'lightgallery';
import 'lg-thumbnail';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import archive from './routes/archive';
import singlePost from './routes/single_post';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    common,
    // Home page
    home,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,

    archive,
    
    singlePost,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
